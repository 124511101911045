import React, { FunctionComponent } from 'react'
import { Content } from '../content'
import styled from 'styled-components'
import Left from './Left'
import Right from './Right'


const Lecture = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-top: 10px;
  border: 1px solid #bfc7d0;
`

export interface OfflineContent extends Content {
  pass_link: string,
  tag_word: string,
  headline: string,
  start_date: string,
  end_date: string,
  day_time_str: string,
  is_sold_out: boolean
}

export interface OfflineContentProps {
  data: OfflineContent
}

const Offline: FunctionComponent<OfflineContentProps> = ({data}) =>
  <Lecture className="lecture">
    <Left data={data}></Left>
    <Right data={data}></Right>
  </Lecture>


export default Offline