export const DATA_KEY = 'data-key'

const useMock = process.env.USE_MOCK === 'true'

export function getWixData(onSuccess, onFailure) {
  if (typeof window === 'undefined') {
    // Gatsby build 과정에서 static file들을 생성 시
    return
  }

  if (useMock) {
    const raw = localStorage.getItem(DATA_KEY)
    const value = JSON.parse(raw)

    setTimeout(() => {
      // execute in next tick
      onSuccess({ [DATA_KEY]: value })
    }, 0)
  } else {
    Wix.Data.Public.get(
      DATA_KEY,
      { scope: 'COMPONENT' },
      onSuccess,
      onFailure,
    )
  }
}

export function setWixData(value, onSuccess, onFailure) {
  if (useMock) {
    localStorage.setItem(DATA_KEY, JSON.stringify(value))

    setTimeout(() => {
      // execute in next tick
      onSuccess({ [DATA_KEY]: value })
    }, 0)
  } else {
    Wix.Data.Public.set(
      DATA_KEY,
      value,
      { scope: 'COMPONENT' },
      onSuccess,
      onFailure,
    )
  }
}

export function triggerSettingsUpdatedEvent(data) {
  if (useMock) {
    localStorage.setItem(Wix.Events.SETTINGS_UPDATED, JSON.stringify(data))
  } else {
    Wix.Settings.triggerSettingsUpdatedEvent(data)
  }
}

const handlersMap = new Map<string, any[]>()

if (useMock) {
  setInterval(() => {
    for (const [type, handlers] of handlersMap) {
      const data = localStorage.getItem(type)
      if (!data) {
        return
      }
  
      localStorage.removeItem(type)
  
      for (const handler of handlers) {
        try {
          handler(JSON.parse(data))
        } catch (err) {
          console.error('invalid message', err)
        }
      }
    }
  }, 1000)  
}

export function addEventListener(type: any, handler: any) {
  if (useMock) {
    let handlers = handlersMap.get(type)
    if (!handlers) {
      handlers = []
      handlersMap.set(type, handlers)
    }

    handlers.push(handler)
  } else {
    Wix.addEventListener(type, handler)
  }
}

export function removeEventListener(type: any, handler: any) {
  if (useMock) {
    let handlers = handlersMap.get(type)
    if (!handlers) {
      return
    }

    const index = handlers.indexOf(handler)
    handlers.splice(index, 1)
  } else {
    Wix.removeEventListener(type, handler)
  }
}

export function setHeight(height: number) {
  console.log(height)
  if (useMock) {

  } else {
    Wix.resizeComponent({
      height
   })
  }
}
