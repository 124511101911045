import React, { Component } from 'react'
import { WixDataLoader } from '@/components/loader'
import { addEventListener, removeEventListener, setHeight } from '@/components/wix'
import LectureList, { LectureListProps } from './components/LectureList'
import styled from 'styled-components'

interface WidgetProps {
  email?: string
}

interface MonitorProps {
  onSettingsUpdate: () => any
}

class Monitor extends Component<MonitorProps> {
  public componentDidMount() {
    addEventListener(
      Wix.Events.SETTINGS_UPDATED,
      this.handleSettingsUpdate,
    )
  }

  public componentWillUnmount() {
    removeEventListener(
      Wix.Events.SETTINGS_UPDATED,
      this.handleSettingsUpdate,
    )
  }

  private handleSettingsUpdate = () => {
    this.props.onSettingsUpdate()
  }

  public render() {
    return null
  }
}

const LectureWrapper = styled.div`
  width: 100%;
`

class Widget extends Component<WidgetProps> {
  // public componentDidMount() {
  //   this.updateCompHeight();

  //   // You can get the style params programmatically, un-comment the following snippet to see how it works:
  //   /*Wix.Styles.getStyleParams(function (style) {
  //    console.log(style);
  //    });*/

  //   // You can also get the style every time it changes, try this:
  //   /*Wix.addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, function (style) {
  //    console.log(style);
  //    });*/
  // }

  private updateCompHeight = () => {
    document.documentElement.scrollTop = 0
    const wrapperEl = document.documentElement.querySelector('#wrapper')
    if (wrapperEl && wrapperEl.clientHeight !== 0) {
      setHeight(document.documentElement.querySelector('#wrapper').clientHeight)
    } else {
      setHeight(document.documentElement.scrollHeight)
    }
  }

  public render() {
    return (
      <WixDataLoader
        render={
          (data: LectureListProps, { refetch }) => {
            return (
              <LectureWrapper id="wrapper">
                <Monitor onSettingsUpdate={refetch} />
                {data ? (
                  <LectureList
                    service={data.service}
                    categories={data.categories}
                    contentType={data.contentType}
                    onUpdate={this.updateCompHeight}
                  />
                ) : (
                  <p>설정 값이 필요합니다.</p>
                )}
              </LectureWrapper>
            )
          }
        }
        />
    )
  }
}

export default Widget
