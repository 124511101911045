import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Left from './Left'
import Right from './Right'
import { Content } from '../content'

const Lecture = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-top: 10px;
  border: 1px solid #bfc7d0;
`

export interface VideoContent extends Content {  
  short_description: string,
  sample_url: string,
  book_url: string
}

export interface VideContentProps {
  data: VideoContent
}

const Video: FunctionComponent<VideContentProps> = ({data}) =>
  <Lecture className="lecture">
    <Left data={data}></Left>
    <Right data={data}></Right>
  </Lecture>

export default Video