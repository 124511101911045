import 'normalize.css'
import './global.css'

import React, { FunctionComponent } from 'react'
import axios from 'axios'
import { AxiosContext } from './context'

const instance = axios.create({
  baseURL: process.env.API_BASE_URL,
})

const Layout: FunctionComponent = ({ children }) => {
  return (
    <>
      <AxiosContext.Provider value={instance}>
        {children}
      </AxiosContext.Provider>
    </>
  )
}

export default Layout
