import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { OfflineContentProps } from './'
import { makeUrl } from '@/utils'
import { Link } from './Left'

const Wrapper = styled.div`
  flex: 1;
  background: #f5f5f5;
  padding: 24px 14px;
`
const Info = styled.ul`
  font-size: 13px;
  line-height: 22px;
  color: #666;

  .title {
    display: inline-block;
    vertical-align: top;
    width: 73px;
    font-weight: bold;
  }
  .money-wrapper {
    display: inline-block;
  }
  .money {
    font-size: 16px;
    font-weight: bold;
  }
  .origin {
    display:block;
    font-size: 14px;
    text-decoration:line-through;
  }
`

const Button = styled.button`
  border: 1px solid #0054a7;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  width: 50%;
  float: left;
  height: 30px;
  line-height: 28px;
  margin-top: 16px;
`

const DetailButton = styled(Button)`
  color: #0054a7;
  background-color: #fff;
`

const OrderButton = styled(Button)`
  background-color: #0054a7;
  color: white;
`

const Right: FunctionComponent<OfflineContentProps> = ({data}) => {
  const detailUrl = makeUrl(`/offline/lecture/${data.id}?service=weport`)
  const orderUrl = makeUrl(`/payment/order?content_id=${data.id}&service=weport`)
  return (
    <Wrapper>
      <Info>
        <li>
          <span className="title">선생님</span>
          <span className="value">{data.teacher_name}</span>
        </li>
        <li>
          <span className="title">개강일</span>
          <span className="value">{data.start_date}</span>
        </li>
        <li>
          <span className="title">종강일</span>
          <span className="value">{data.end_date}</span>
        </li>
        <li>
          <span className="title">요일, 시간</span>
          <span className="value">{data.day_time_str}</span>
        </li>
        <li>
          <span className="title">수강료</span>
          <div className="money-wrapper">
            {data.original_price && 
              <span className="value origin">{data.original_price}원</span>
            }
            <span className="value money">{data.price}원</span>
          </div>        
        </li>
      </Info>
      <Link href={detailUrl}>
        <DetailButton>상세보기</DetailButton>
      </Link>
      <a href={orderUrl} target='_blank'>
        <OrderButton>수강신청</OrderButton>
      </a>      
    </Wrapper>
  )
}

export default Right
