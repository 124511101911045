import React, { Component, ReactNode } from 'react'
import { getWixData } from './wix'

export const DATA_KEY = 'data-key'

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

interface WixDataLoaderContext {
  refetch: () => any
}

interface WixDataLoaderProps<T> {
  render: (data: T, context?: WixDataLoaderContext) => ReactNode
}

interface WixDataLoaderState<T> {
  loadingState: LoadingState
  data: T
}

export class WixDataLoader<T> extends Component<WixDataLoaderProps<T>, WixDataLoaderState<T>> {
  constructor(props: any) {
    super(props)

    this.state = {
      loadingState: LoadingState.LOADING,
      data: null,
    }

    this.fetch()
  }

  private fetch = () => {
    getWixData(
      result => {
        const data = result[DATA_KEY]

        this.setState({
          loadingState: LoadingState.LOADED,
          data,
        })
      },
      reason => this.setState({
        loadingState: LoadingState.FAILED,
      }),
    )
  }

  public render() {
    const { render } = this.props
    const { loadingState, data } = this.state

    switch (loadingState) {
      case LoadingState.LOADING: {
        return (
          <div>Loading...</div>
        )
      }
      // case LoadingState.FAILED: {
      //   return (
      //     <div>
      //       Failed.
      //     </div>
      //   )
      // }
    }

    return render(data, {
      refetch: this.fetch,
    })
  }
}
