import React, { FormEvent, FunctionComponent } from 'react'
import styled from 'styled-components'
import { makeUrl } from '@/utils'
import { OfflineContentProps } from './'
import pencilPNG from './pencil.png'
import { Keyword } from '../content'

const Wrapper = styled.div`
  flex: 4;
  display: flex;
  background-color: #fff;
  padding: 23px 0 9px 10px;
`

const Image = styled.div`
  width: 150px;
  img {
    cursor: pointer;
  }
  p {
    margin-top: 5px;
  }
  a {
    font-size: 12px;
    color: #000;   
  }
  .pencil {
    padding-left: 18px; 
    background: url(${pencilPNG}) no-repeat left;
    background-size: 17px;
  }
`

const SubjectWrapper = styled.div`
  margin-left: 20px;
  width: 70%;
  font-size: 12px;
  .warning {
    font-weight: bold;
  }
`

const KeywordWrapper = styled.div`
  margin-top: 20px;
  color: #0054a7;
  font-weight: bold;
  span {
    margin-right: 5px;
  }
`

const Headline = styled.p`  
  margin-top: 17px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  color: #666;
  line-height: 17px;
`

const Subject = styled.h3`
  margin-top: 0;
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  font-weight: bold;
  color: #0054a7;
  border-bottom: 1px solid #0054a7;
`

const Label = styled.span`
  font-size: 12px;
  padding: 1px 2px;
  color: #fff;
  display: inline-block;
  height: 16px;
  line-height: 16px;
  margin-left: 5px;
  &.NEW {
    background-color: #0054a7;
  }

  &.BEST {
    background-color: #C8C8C8;
  }

  &.HOT {
    background-color: #a11414;
  }

  &.조기마감, &.마감임박 {
    background-color: #d7004c;
  }

  &.마감 {
    background-color: #5a5a5a;
  }

  &.선착순 {
    background-color: #286f14;
  }

  &.특별할인 {
    background-color: #000;
  }
`

export const Link: FunctionComponent<{href: string}> = ({href, children}) => 
  <a target='popup' href={href} onClick={e => 
    window.open(href, 'pop_detail', 'width=832, height=980')}>
    {children}
  </a>


const Left: FunctionComponent<OfflineContentProps> = ({data}) => {
  const detailUrl = makeUrl(`/offline/lecture/${data.id}?service=weport`)
  return (
    <Wrapper>
      <Image>
        <Link href={detailUrl}>
          <img src={data.list_image}></img>
        </Link>        
        <p>
          <Link href={detailUrl + '#3'}><span className='pencil'>후기</span></Link>
          <a href={data.pass_link} target='_blank'><span className='pencil'>합격자 수기</span></a>
        </p>
      </Image>
      <SubjectWrapper>
        <Link href={detailUrl}>
          <Subject>
            {data.subject}
            <Label className={data.tag_word}>{data.tag_word}</Label>
          </Subject>
        </Link>        
        <Headline>
          {data.headline}
        </Headline>        
        <p className='warning'>정확한 강의 요일과 시간은 상세보기의 유의사항을 참고하시기 바랍니다.</p>
        <KeywordWrapper>
          {data.keywords.map((k: Keyword) => 
            <span key={k.name}>#{k.name}</span>
          )}
        </KeywordWrapper>
      </SubjectWrapper>
    </Wrapper>
  )
}
export default Left
