import React from 'react'
import Layout from '@/components/Layout'
import Widget from '@/components/contents'

export default () => {
  return (
    <Layout>
      <Widget />
    </Layout>
  )
}
