import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import sampleBG from './sample.png'
import bookBG from './book.png'
import { VideContentProps } from './'
import { Keyword } from '../content'
import { makeUrl } from '@/utils'

const Wrapper = styled.div`
  flex: 4;
  background-color: #fff;
  padding: 23px 0 9px 10px;
`

const ContentWrapper = styled.div`
  min-height: 130px;
`

const ImageLink = styled.a`
  display: inline-block;
  width: 12%;
  min-width: 122px;
`

const ListImage = styled.img`
  border: 1px solid #ccc;
`

const SubjectWrapper = styled.div`
  display: inline-block;
  margin-left: 20px;
  width: 70%;
  vertical-align: top;
`

const Subject = styled.h3`
  margin-top: 10px;
`

const SubjectLink = styled.a`
  text-overflow: ellipsis;
  max-height: 52px;
  font-size: 20px;
  font-weight: bold;
  color: #222222;
  word-wrap: break-word;
  line-height: 26px;
`

const Description = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: #666;

  a {
    color: #333;
    text-decoration: none;
    white-space: pre-line;
  }
`

const Link = styled.a`
  font-size: 13px;
  color: #666;
  padding: 0;
  font-weight: bold;
`
const SampleLink = styled(Link)`
  span {
    background: url(${sampleBG}) no-repeat left 1px;
    color: #0d76c7;
    padding: 0 0 0 20px;
  }
`

const BookLink = styled(Link)`
  margin-left: 10px;
  span {
    background: url(${bookBG}) no-repeat left 1px;
    padding: 0 0 0 22px;
  }
`

const KeywordWrapper = styled.div`
  margin-top: 15px; 
  clear: both;
`

const KeywordLink = styled.a`
  font-size: 12px;
  float: left;
  margin-right: 6px;
  padding: 0 0 0 6px;
  border-radius: 3px;
  background: #eee;
  line-height: 24px;
  font-weight: bold;
  color: #000;

  span {
    display: block;
    padding-right: 6px;
  }
`

const Label = styled.span`
  display: inline-block;
  margin-left: 5px;
  padding: 3px 5px 0px 5px;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  border-radius: 3px;

  &.new, &.best {
    color: #0d78c6;
    border: 1px solid #0d78c6;
  }

  &.hot {
    color: #ec175a;
    border: 1px solid #ec175a;
  }
`

const Left: FunctionComponent<VideContentProps> = ({data}) =>
  <Wrapper>
    <ContentWrapper>
      <ImageLink href={makeUrl(data.url)} target='_blank'>
        <ListImage src={data.list_image}></ListImage>
      </ImageLink>
      <SubjectWrapper>
        <Subject>
          <SubjectLink href={makeUrl(data.url)} target='_blank'>
            <span>{data.subject}</span>
            {data.tag && <Label className={data.tag}>{data.tag.toUpperCase()}</Label>}              
          </SubjectLink>
        </Subject>
        <Description>
          <a href={makeUrl(data.url)}>
            { 
              data.short_description &&
              data.short_description.split('<br>').map((line, i) => <span key={i}>{line}<br/></span>)
            }
          </a>
        </Description>
        <div>
          {data.sample_url ?
            <SampleLink href={data.sample_url} target='_blank'>
              <span>샘플보기</span>
            </SampleLink> : null
          }
          {data.book_url ?
            <BookLink href={data.book_url} target='_blank'>
              <span>도서구매</span>
            </BookLink> : null
          }
        </div>
      </SubjectWrapper>
    </ContentWrapper>        
    <KeywordWrapper>
      {data.keywords.map((keyword: Keyword) => 
        <KeywordLink href={makeUrl(keyword.url)} key={keyword.name} target='_blank'>
          <span>#{keyword.name}</span>
        </KeywordLink>
      )}
    </KeywordWrapper>
  </Wrapper>

export default Left