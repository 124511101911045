import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { VideContentProps } from './'
import { makeUrl } from '@/utils'
import starOn from './starbar_on.png'
import starOff from './starbar_off.png'

const Wrapper = styled.div`
  flex: 1;
  padding: 21px 30px;
  background-color: #f4f4f4;
`

const Info = styled.ul`
  font-size: 13px;
  line-height: 22px;
  color: #666;

  .title {
    display: inline-block;
    vertical-align: top;
    width: 73px;
    font-weight: bold;
  }
  .money-wrapper {
    display: inline-block;
  }
  .money {
    font-size: 16px;
    font-weight: bold;
  }
  .origin {
    display:block;
    font-size: 14px;
    text-decoration:line-through;
  }
`

const Star = styled.span<{ width: string }>`
  .bar {
    display: inline-block;
    width: 49px;
    height: 10px;
    margin: 7px 0 0;
    background: url(${starOff}) no-repeat left top;
    background-size: 49px 10px;
  }
  .bar em {
    display: block;
    height: 10px;
    background: url(${starOn}) no-repeat left top;
    background-size: 49px 10px;
    width: ${props => props.width + '%' }
  }
  .average {
    font-size: 11px;
    padding-left:3px;
    color: #666;
  }
`

const Button = styled.a`
  font-weight: bold;
  font-size: 13px;
  line-height: 33px;
  display: block;
  text-align: center;
`

const ReviewButton = styled(Button)`
  margin-top: 11px;
  line-height: 29px;
  background: #ffffff;
  border: 2px solid #0d76c7;
  color: #0d76c7;
`

const DetailButton = styled(Button)`
  margin-top: 3px;
  background: #0d76c7;
  color: #fff;
`

const Right: FunctionComponent<VideContentProps> = ({data}) =>
  <Wrapper>
    <Info>
      <li>
        <span className="title">선생님</span>
        <span className="value">{data.teacher_name}</span>
      </li>
      <li>
        <span className="title">강좌/기간</span>
        <span className="value">{data.lecture_count}강/{data.expire}일</span>
      </li>    
      <li>
        <span className="title">만족도</span>
        <span className="value">
          <Star width={(data.avg_score * 10 * 2).toFixed(1)}>
            <span className="bar"><em></em></span>
            <span className="average">(<em>{data.avg_score}</em>)</span>
          </Star>
        </span>
      </li>
      <li>
        <span className="title">수강료</span>
        <div className="money-wrapper">
          {data.original_price && 
            <span className="value origin">{data.original_price}원</span>
          }
          <span className="value money">{data.price}원</span>
        </div>        
      </li>     
    </Info>
    <ReviewButton href={makeUrl(data.review_url)} target='_blank'>후기보기({data.review_count})</ReviewButton>
    <DetailButton href={makeUrl(data.url)} target='_blank'>상세보기</DetailButton>
  </Wrapper>

export default Right