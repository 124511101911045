import React, { FunctionComponent, useState, useEffect, useContext, useLayoutEffect } from 'react'
import { AxiosContext } from '@/components/context'
import Video from '../layout/video'
import Offline from '../layout/offline'

export interface LectureListProps {
  service: string,
  contentType: string,
  categories: number[]
  onUpdate: () => any
}

interface LecturesData {
  results: any[]
}

const LectureList: FunctionComponent<LectureListProps> = ({ service, categories, contentType, onUpdate }) => {
  const [lectures, setLectures] = useState([])

  const axios = useContext(AxiosContext)
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get<LecturesData>('/iframe/contents/', {
        params: {
          service: 'weport',
          service_name: service,
          content_type: contentType,
          categories: categories.join(','),
        },
      })
      setLectures(data.results)
      onUpdate()
    }

    fetchData()
  }, [service, contentType, categories])

  useLayoutEffect(onUpdate, [])

  
  
  return (
    <>
      {lectures.map(lecture => {
        switch (contentType) {
          case 'video':
            return (
              <Video
                data={lecture}
                key={lecture.id}
              />
            )
          case 'offline':
            return (
              <Offline
                data={lecture}
                key={lecture.id}
              />
            )
        }          
      })}

    </>
  )
}

export default LectureList
